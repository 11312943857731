import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Wrapper from '@/views/Layouts/LayoutWrapper.vue';
import WelcomeView from '@/views/WelcomeView.vue';
import TermsAndConditionsView from '@/views/TermsAndConditionsView.vue';
import HomeView from '@/views/HomeView.vue';
import HomeMobileView from '@/views/mobile/HomeMobileView.vue';
import WelcomeMobileView from '@/views/mobile/WelcomeMobileView.vue';
import { isMobileScreen } from '@/helpers/common';
import store from '@/store';

function load(path: string) {
  // '@' is aliased to src/components
  return () => import(`@/views/${path}.vue`)
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        name: 'welcome',
        component: isMobileScreen() ? WelcomeMobileView : WelcomeView,
        meta: {
          protected: false,
          layout: 'guest',
        },
      },
      {
        path: '/home',
        name: 'home',
        component: isMobileScreen() ? HomeMobileView : HomeView,
        meta: {
          protected: false,
          layout: isMobileScreen() ? 'homeMobile' : 'home',
        },
      },
      {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: TermsAndConditionsView,
        meta: {
          protected: false,
          layout: 'home',
        },
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'page-not-found',
        component: load('PageNotFound'),
        meta: {
          notFound: true,
          protected: false,
          layout: 'guest'
        }
      }
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

router.beforeEach((to) => {
  store.commit('SET_CURRENT_ROUTE', to.name);
});

export default router;
