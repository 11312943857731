<template>
	<footer class="bottom-0 left-0 right-0 p-[20px] min-h-[74px] max-h-[74px]">
		<!-- Footer content goes here -->
		<div class="flex justify-between items-center">
      <div class="flex w-[50vw]" :class="{'justify-around': !isMobile, 'justify-start': isMobile}">
        <div>
          <h3 class="text-gray-light font-jost-regular font-medium text-[12px] leading-[26px] uppercase">&copy; 2023 GEEQ.IO</h3>
        </div>
        <div v-if="!isMobile">
          <h3 class="text-gray-light font-jost-regular font-medium text-[12px] leading-[26px] uppercase">GEEQ NFT V{{ appVersion }}</h3>
        </div>
        <div v-if="!isMobile">
          <a href="https://geeq.io/privacy-policy/" target="_blank" class="!text-blue-dark font-jost-regular font-medium text-[12px] leading-[26px] uppercase">PRIVACY POLICY</a>
        </div>
        <div v-if="!isMobile">
          <router-link :to="{name: 'terms-and-conditions'}" class="!text-blue-dark font-jost-regular font-medium text-[12px] leading-[26px] uppercase">TERMS AND CONDITIONS</router-link>
        </div>
      </div>
      <div class="flex justify-between gap-[10px]" :class="{'mr-[70px]': !isMobile}">
        <a href="https://geeq.io" target="_blank" :class="{'w-[40px] h-[40px]': isMobile}" class="!text-blue-dark font-jost-regular font-medium text-[12px] leading-[26px] uppercase">
          <img src="../../public/images/web-icon.svg" alt="twitter">
        </a>
        <a href="https://twitter.com/GeeqOfficial" :class="{'w-[40px] h-[40px]': isMobile}" target="_blank" class="!text-blue-dark font-jost-regular font-medium text-[12px] leading-[26px] uppercase">
          <img src="../../public/images/twitter-icon.svg" alt="twitter">
        </a>
        <a href="https://t.me/GeeqOfficial" :class="{'w-[40px] h-[40px]': isMobile}" target="_blank" class="!text-blue-dark font-jost-regular font-medium text-[12px] leading-[26px] uppercase">
          <img src="../../public/images/telegram-icon.svg" alt="twitter">
        </a>
        <a href="https://www.linkedin.com/company/geeq" :class="{'w-[40px] h-[40px]': isMobile}" target="_blank" class="!text-blue-dark font-jost-regular font-medium text-[12px] leading-[26px] uppercase">
          <img src="../../public/images/linkedin-icon.svg" alt="twitter">
        </a>
      </div>
		</div>
	</footer>
</template>

<script>
import { isMobileScreen } from '@/helpers/common';

export default {
	name: 'FooterComponent',
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    isMobile() {
      return isMobileScreen();
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
.shadow-top {
	box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);
}
footer {
  background: #FFFFFF;
  border-top: 1px solid #CDEDFF;
}
</style>
