<template>
	<button
		:type="type"
		class="button text-[24px] px-[20px] py-[17px]"
		:class="[`${classes}`]"
		@click="handleClick"
	>
		{{ label }}
	</button>
</template>

<script>
export default {
	name: 'ButtonComponent',
	props: {
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		classes: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick() {
			// handle button click event
			this.$emit('onClick');
		},
	},
};
</script>

<style scoped>
.button {
	/* button styles */
}
</style>
