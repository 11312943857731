<template>
  <section class="main_section">
    <StepsComponent v-if="currentStep < 4" />
    <ChooseCardFile v-if="currentStep === 1" />
    <AddCardMessage v-if="currentStep === 2" />
    <SignAndSend v-if="currentStep === 3" />
    <CongratulationsComponent v-if="currentStep === 4" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import StepsComponent from '@/components/common/StepsComponent.vue';
import ChooseCardFile from '@/components/steps/ChooseCardFile.vue';
import AddCardMessage from '@/components/steps/AddCardMessage.vue';
import SignAndSend from '@/components/steps/SignAndSendCard.vue';
import CongratulationsComponent from '@/components/steps/CongratulationsComponent.vue';

export default {
  name: 'AuthWrapper',
  components: {
    StepsComponent,
    ChooseCardFile,
    AddCardMessage,
    SignAndSend,
    CongratulationsComponent,
  },
  computed: {
    ...mapState(['currentStep', 'passedSteps']),
  },
}
</script>

<style scoped>
.main_section {
  background: url('../../../public/images/steps-container-bg.png') no-repeat;
  background-position: center top;
}
</style>
