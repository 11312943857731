<template>
  <div class="home mobile relative overflow-hidden">
    <SnowComponent />
    <div class="relative m-auto flex flex-col items-center justify-between">
    </div>
    <section class="gallery-section-wrapper relative">
    <img class="bg_top" src="../../../public/images/bg_top_mobile.svg" alt="Poster" />

       <!-- Page Top section -->
       <div class="intro flex flex-col justify-end items-center">
        <!-- Button to request to start minting process -->
        <GeeqButtonComponent
            @onClick="handleClick"
            classes="btn-mint mx-auto font-jost-medium uppercase !bg-[#3375EE] !text-[20px] text-white !min-h-[76px] !w-[335px] !mt-0 !p-0"
            label="MINT YOUR CARD"
            type="button"
            />
        <!-- Button to request to start minting process -->
      <Heading2Component
        class="!mt-[30px] !w-[100%] mx-auto text-center font-black !text-blue-dark !leading-[40px] font-jost-black !text-[32px]"
        text="Happy Holidays to you, dear Geeqs!"
        id="galleryHeading" />
      <p class="text-[18px] leading-[26px] text-blue-dark mt-[20px] max-w-[335px]">
        These 2023 NFTs are already safe in Santa's vault.
      </p>
      </div>
      <!-- /Page Top section -->

      <div class="mt-[20px]">
        <!-- File gallery section -->
        <CardGallery :files="files" classes="file_gallery_mobile mt-[20px]" />
        <!-- /File gallery section -->
      </div>
    </section>
      <!-- Click to request to start minting process -->
      <section class="request_to_mint_cont flex gap-[40px] justify-center items-end flex">
        <!-- Form to request minting of NFT -->
        <div class="pb-[80px]">
          <!-- Heading -->
          <Heading2Component class="!text-white font-jost-black !font-black !font-jost-black !text-[32px] !leading-[40px]" text="Ready to mint yours?" />
          <!-- /Heading -->

          <!-- Button -->
          <GeeqButton2Component
            @onClick="handleClick"
            classes="btn-mint !font-bold !w-[300px] !max-w-[335px] !h-[56px] !mt-[25px]"
            type="button"
            label="MINT YOUR CARD"
          />
          <!-- /Button -->
        </div>
      </section>
      <!-- /Click to request to start minting process -->

    <!-- <img class="bg_bottom" src="../../public/images/Background-home-b.svg" alt="Poster" /> -->
  </div>
</template>

<script>
import GeeqButtonComponent from '@/components/common/GeeqButtonComponent.vue';
import Heading2Component from '@/components/common/Heading2Component.vue';
import CardGallery from '@/components/CardGallery.vue';
import SnowComponent from '@/components/common/SnowComponent.vue';
import GeeqButton2Component from '@/components/common/GeeqButton2Component.vue';
import { navigateTo } from '@/helpers/navigation';
import { mapState } from 'vuex';
import api from '@/helpers/api'

export default {
  components: {
    GeeqButton2Component,
    GeeqButtonComponent,
    Heading2Component,
    CardGallery,
    SnowComponent,
  },
  computed: {
    ...mapState({
      xRedirectionUrl: (state) => state.xRedirectionUrl,
    }),
  },
  data() {
    return {
      skip: 0,
      limit: 9,
      hide: true,
      files: [],
    };
  },
  async created() {
    this.$store.dispatch('resetUsersProgress');

    const { skip, limit, hide } = this;
    api.getFiles({ skip, limit, hide }).then((res) => {
      this.files = [...res.data.result];
    });
  },
  methods: {
    // Handle click event to navigate to "connect-account" page.
    handleClick() {
      navigateTo('home');
    },
  },
};
</script>
<style lang="css">
  .mobile .file_gallery_mobile {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
    text-align: center;
    justify-content: center;
    justify-items: center;
    margin: auto;
  }
  .mobile .file_gallery_mobile .card_item {
      width: unset;
      max-width: 161px;
      min-width: 161px;
      min-height: 186px;
      max-height: 186px;
      padding: 10px;
  }
  .mobile .file_gallery_mobile .card_item img {
      width: 141px;
      min-height: 116px;
      max-height: 116px;
      border-radius: 12px;
  }
  .mobile .file_gallery_mobile .card_item h3 {
      margin-top: 10px;
      line-height: 20px;
  }
  .mobile .request_to_mint_cont {
    margin-top: -85px;
    min-height: 330px;
    background: url('../../../public/images/bg_bottom_mobile.svg') no-repeat;
    background-position: top;
    background-size: cover;
  }

  img.bg_top {
    background: #3375ed;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  img.bg_bottom {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .intro {
      top: -270px;
    }
  .gallery-section-wrapper {
    min-height: 570px;
  }
  .intro .heading2,
  .request_to_mint_cont .heading2 {
      line-height: 20px;
      margin-top: 57px;
    }
  .request_to_mint_cont .btn-mint {
  max-width: 320px !important;
  min-width: 320px !important;
 }
</style>
