import { setToStorage, getFromStorage, deleteFromStorage } from '@/helpers/sessionStorage';
import { getClaimsFromToken, isTokenExpired } from './jwt-token';

export function init(store: any, router: any) {
  const token = getFromStorage('token');
  if (token) {
    const claims = getClaimsFromToken(token) as { exp: number } | null;
    if (claims) {
      const { exp } = claims;
      const isExpired = isTokenExpired(exp);
      if (isExpired) {
        console.log('Token Expired');
        deleteFromStorage('token');
        store.commit('LOGOUT');
      }
    }
  }
// axios.defaults.headers.common.authorization = 'adfsgehbaldkgmbalmkblDKFMBDLKFMBLKDMFB';

  // Define Middleware for router
  router.beforeEach((to: any, from: any, next: any) => {
    const { token } = to.query;
    const tokenStored = getFromStorage('token');
    if (token) {
      setToStorage('token', token);
      store.dispatch('LOGIN');
    } else if (tokenStored) {
      store.dispatch('LOGIN');
    }
    next();
  });
}
