<template>
	<section class="request_to_mint_cont flex gap-[40px] justify-center items-end flex">
		<!-- Form to request minting of NFT -->
    <div class="pb-[170px]">
      <!-- Heading -->
			<Heading2Component class="!text-white font-jost-black !font-black !font-jost-black !text-[32px] !leading-[40px]" text="Ready to mint yours?" />
			<!-- /Heading -->

			<!-- Button -->
			<GeeqButton2Component
        @onClick="handleClick"
        classes="!font-bold !w-[636px]"
        type="button"
        label="MINT YOUR CARD"
      />
			<!-- /Button -->
    </div>
	</section>
</template>

<script>
import GeeqButton2Component from '@/components/common/GeeqButton2Component.vue';
import Heading2Component from '@/components/common/Heading2Component.vue';

export default {
	components: {
		GeeqButton2Component,
		Heading2Component,
	},
	data() {
		return {
			// Form data
		};
	},
	methods: {
		// Handle click event to emit navigateTo "home" event.
		handleClick() {
			this.$emit('navigateTo', 'home');
		},
	},
};
</script>
<style scoped>
  .request_to_mint_cont {
    margin-top: -400px;
    min-height: 800px;
    background: url('../../public/images/bg-bottom.svg') no-repeat;
    background-position: center bottom;
    background-size: cover;
  }
</style>
