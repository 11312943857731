<template>
  <div class="intro flex flex-col justify-end items-center">
    <!-- Button to request to start minting process -->
    <GeeqButtonComponent
        @onClick="handleClick"
        classes="mx-auto font-jost-medium uppercase !bg-[#3375EE] !text-[20px] text-white !min-h-[76px] !w-[620px] mt-[30px] !p-0"
        label="MINT YOUR CARD"
        type="button"
        />
    <!-- Button to request to start minting process -->
  <Heading2Component
    class="mt-[20px] mx-auto text-center font-black !text-blue-dark !leading-[40px] font-jost-black !text-[32px]"
    text="Happy Holidays to you, dear Geeqs!" id="galleryHeading" />
  <p class="text-[22px] leading-[26px] text-blue-dark mt-[20px]">
    These 2023 NFTs are already safe in Santa's vault.
  </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import GeeqButtonComponent from '@/components/common/GeeqButtonComponent.vue';
import Heading2Component from '@/components/common/Heading2Component.vue';

export default defineComponent({
  name: 'IntroComponent',
  emits: ['navigateTo'],
  components: {
    GeeqButtonComponent,
    Heading2Component,
  },
  props: {
    msg: String,
  },
  methods: {
    // Handle click event to emit navigateTo "connect-account" event.
    handleClick() {
      this.$emit('navigateTo', 'home');
    },
    // Click to Scroll to gallery section.
    scrollToGallery() {
      const gallery = document.getElementById('galleryHeading') as HTMLElement;
      gallery.scrollIntoView({ behavior: 'smooth' });
    },
  },
});
</script>
<style lang="scss" scoped>
  .intro {
    margin-top: -115px;
  }
@media only screen and (max-width: 1399px) {
  .intro {
    margin-top: -90px;
  }
}
@media only screen and (max-width: 991px) {
  .intro {
    margin-top: -50px;
  }
}
</style>
