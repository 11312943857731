<template>
  <span class="base-timer__label text-[40px]">{{ formattedTimeLeft }}</span>

  <div class="w-[100%] relative">

    <img class="w-full timer-img h-[15px] absolute top-0" src="../../../public/images/timer_linear.svg" />

    <svg class="progress-bar absolute top-[-8px] left-[5px] rounded-[8px]" viewBox="0 0 100 10" xmlns="http://www.w3.org/2000/svg">
    <line
      class="progress-bar__progress"
      :class="remainingPathColor"
      :stroke-dasharray="lineDasharray"
      :stroke-dashoffset="lineDashoffset"
      x1="0" y1="5" x2="100" y2="5" stroke-width="1"
      :stroke="remainingPathColor"
    ></line>
  </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const FULL_DASH_ARRAY = 100;

export default {
  data() {
    return {
      timeLeft: 120, // replace this with your actual total time
      totalTime: 120, // add this line
      intervalId: null
    };
  },
  computed: {
    ...mapState(['selectedFile']),
    lineDasharray() {
      return FULL_DASH_ARRAY;
    },
    lineDashoffset() {
      const rawTimeFraction = this.timeLeft / this.totalTime;
      return (1 - rawTimeFraction) * FULL_DASH_ARRAY;
    },
    remainingPathColor() {
      // replace this with your actual logic for determining the path color
      return '#00C2FF'; // replace 20 with your actual warning threshold
    },
    formattedTimeLeft() {
      const { timeLeft } = this;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
  },
  watch: {
    selectedFile() {
      this.timeLeft = 120;
    },
    timeLeft() {
      if (this.timeLeft === 0) {
        this.onTimesUp();
      }
    }
  },
  mounted() {
    this.intervalId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft -= 1;
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
  methods: {
    onTimesUp() {
      this.$store.dispatch('SET_TIME_OUT', true);
    },
  }
};
</script>

<style scoped>
.base-timer__label {
    font-size: 40px;
    color: #00C2FF;
  }
</style>
