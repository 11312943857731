<template>
	<section id="gallery" class="file-gallery">
		<!-- If the list is empty -->
		<p
      v-if="items.length<1"
      class="text-center font-jost-semibold text-[20px] text-gray-dark px-[2.5%] mt-[50px]"
    >
      The gallery is empty..
    </p>
		<!-- /If the list is empty -->

		<div class="px-[2.5%]" :class="[`${classes}`]">
			<!-- Loops over Gallery list and renders each single Gallery item -->
			<SingleFile
      v-for="file in items"
      :key="file.id"
      :file="file"
      class="!cursor-pointer"
      @click="selectFile(file)"
      />
			<!-- /Loops over Gallery list and renders each single Gallery item -->
      <div ref="observer"></div>
		</div>
	</section>
</template>

<script>
import SingleFile from '@/components/SingleFile.vue';
import api from '@/helpers/api';

export default {
	name: 'FileGallery',
	components: {
		SingleFile,
	},
	props: {
		classes: {
			type: String,
			default: '',
		},
    isHidden: {
      type: Boolean,
      default: false,
    },
	},
  watch: {
    isHidden(val) {
      this.skip = this.initialSkip;
      const params = {
        hide: val,
        skip: this.skip,
        limit: this.limit,
      };
      api.getFiles(params).then((res) => {
        this.items = [...res.data.result];
        this.skip += this.limit;
      });
    },
  },
  data() {
    return {
      items: [],
      observer: null,
      initialSkip: 0,
      count: 0,
      skip: 0,
      limit: 12,
    };
  },
  methods: {
    loadMore() {
      const params = {
        hide: this.isHidden,
        skip: this.skip,
        limit: this.limit,
      };
      api.getFiles(params).then((res) => {
        this.items = [...this.items, ...res.data.result];
        this.skip += this.limit;
      });
    },
    handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.loadMore();
        }
      });
    },
    selectFile(file) {
      if (file.status) return;
      this.$emit('select-file', file);
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection);
    this.observer.observe(this.$refs.observer);
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
};
</script>
