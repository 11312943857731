<template>
  <template v-if="currentUser">
    <AuthWrapper />
  </template>
  <template v-else>
    <GuestWrapper />
  </template>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { getFromStorage } from '@/helpers/sessionStorage';
import AuthWrapper from '@/components/wrappers/AuthWrapper.vue';
import GuestWrapper from '@/components/wrappers/GuestWrapper.vue';
import { mapState } from 'vuex';

export default {
  name: 'HomeView',
  components: {
    AuthWrapper,
    GuestWrapper,
  },
  created() {
    if (this.$route.query.auth) {
      const auth = JSON.parse(this.$route.query.auth);
      if (!auth) {
        const msg = 'Twitter Authentication has failed. Please refresh and try again.';
        toast.error(msg, {
          position: "bottom-right",
          duration: 2000,
          timeout: 2000,
        });
      }
    }

  },
  computed: {
    ...mapState(['currentStep', 'passedSteps', 'currentUser']),
  },
  // Your JavaScript code goes here
  data() {
    return {
    };
  },
}
</script>

<style scoped>
/* Your CSS styles go here */
</style>
