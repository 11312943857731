<template>
	<h2 class="heading2">
    {{ text }}
  </h2>
</template>

<script>
export default {
	name: 'Heading2Component',
	props: {
		text: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped>
.heading2 {
	/* Add your styles here */
}
</style>
