<template>
  <div class="main-container overflow-visible pt-[30px] mb-[100px]">
    <div class="px-[15px]">
      <div class="2xl:w-[50%] xl:w-[60%] lg:w-[70%] md:w-[80%] sm:w-[90%] w-[100%] mx-auto">
        <div class="m-auto bg-transparent rounded-[3px]">
          <div>
            <header class="py-[30px]">
              <div class="dialog-title">
                <Heading2Component text="Terms and Conditions" class="jost-bold font-bold !text-blue-light !text-[32px]" />
              </div>
            </header>

            <div class="text-[18px] text-[#333] pt-0 m-0">
              <div class="dialog-title">
                <Heading2Component text="Geeq Code of Conduct and Release of Liability" class="jost-medium font-bold text-blue-dark text-xl mb-[10px] mt-0" />
              </div>
              <p>
                You have been invited to participate in a demonstration of the WebApp interface of the Geeq Data Blockchain, which is owned by Geeq Corporation (“<b>Geeq</b>”).
                The demonstration allows you to upload a document and metadata you provide, (“<b>Content</b>”),
                to the Geeq Data Blockchain as a demonstration of its technical abilities. As a condition of your participation,
                you agree to the following Code of Conduct, and Release of Liability:
              </p>
              <ul class="list-disc">
                <li>You may only upload documents in .pdf or .jpg format that are under 8 MB.</li>
                <li>Your document will be hashed, and the hash will be publically visible on the Geeq Data Blockchain.
                  Metadata you provide will be publically visible on the Geeq Data Blockchain, and due to the nature of block chain technology,
                  once uploaded, may not be deleted. You grant Geeq all rights necessary in your content for the purposes of the demonstration.
                </li>
                <li>
                  <b class="underline">
                    You may only upload Content that you own, that does not violate any law, and that will not bring Geeq or the Geeq Data Blockchain into disrepute.
                  </b>
                  Without limiting the foregoing, you will not upload any Content that:
                  <ul class="ml-14" style="list-style-type: circle;">
                    <li>may, degrade, disparage, tarnish or deprecate Geeq and/or its public image or standing in the community;</li>
                    <li>may be defamatory, trade libelous, pornographic or obscene;</li>
                    <li>
                      contains, without limitation, any of the following: nudity; alcohol/drug consumption or smoking; explicit sexual  activity  or  sexual  innuendo;
                      crude,  vulgar  or  offensive  language  and/or  symbols;  derogatory characterizations  of  any  ethnic,  racial,  sexual,
                      religious  or  other  groups;  content  that  endorses,  condones and/or discusses any illegal, inappropriate or risky behaviour or conduct;
                      personal information; commercial messages, comparisons or solicitations for products or services; any identifiable third party products,
                      trade-marks, third party intellectual property; and/or any other content that is illegal, inappropriate, unsuitable or offensive;
                    </li>
                    <li>
                      infringes, or might infringe, the intellectual property rights, privacy rights, rights of publicity, or
                      other proprietary rights of others or give rise to any claims for payment whatsoever;
                    </li>
                    <li>
                      contains any malware, viruses, trojan horses, time bombs, or any other computer code, files or programs designed  to  interrupt,
                      destroy  or  limit  the  functionality  of  any  computer  software  or  hardware  or telecommunications equipment; and/or
                    </li>
                    <li>
                      contains  (in  any  format  or  media)  any  music,  logos,  trade-marks  or  other  copyrighted  materials
                      (e.g. photographs, pictures, texts, lyrics, etc.) belonging to a third party.
                    </li>
                  </ul>
                </li>
                <li>
                  You understand and agree that there is no ability to download any Content posted to this demonstration version of the Geeq Data Blockchain.
                  You may not rely on it as a means of storage, or retrieval.
                </li>
                <li>
                  All intellectual property in the Geeq Data Blockchain is owned by Geeq or is used with permission.
                  You may not use it for any purpose other than to validate our service, and you may not extract,
                  reproduce or use any of our content in any other way. You shall not gain any intellectual property rights in any such content.
                </li>
                <li>
                  The Geeq Data Blockchain is provided strictly as-is and as-available. Geeq does not provide any warranty of any kind and is not
                  liable for any losses or damages you may suffer as a result of your use or misuse.
                </li>
                <li>
                  Geeq may change, suspend or terminate, temporarily or permanently, the Geeq Data Blockchain or any part or user thereof at any time,
                  for any reason, and without any notice or liability to you or any other entity.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Heading2Component from "../components/common/Heading2Component.vue";
export default {
  name: "TermsAndConditionsView",
  components: { Heading2Component },
}
</script>

<style scoped>
* {
  text-align: left;
}
</style>
