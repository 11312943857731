<template>
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.77 4.84579L12.77 1.51579C11.9494 0.969718 10.9857 0.678375 10 0.678375C9.01431 0.678375 8.0506 0.969718 7.23 1.51579L2.23 4.84579C1.54449 5.30196 0.982257 5.92037 0.59323 6.64609C0.204202 7.37181 0.000425504 8.18238 0 9.00579V17.6758C0 18.3324 0.129329 18.9826 0.380602 19.5892C0.631876 20.1958 1.00017 20.747 1.46447 21.2113C2.40215 22.149 3.67392 22.6758 5 22.6758H15C15.6566 22.6758 16.3068 22.5465 16.9134 22.2952C17.52 22.0439 18.0712 21.6756 18.5355 21.2113C18.9998 20.747 19.3681 20.1958 19.6194 19.5892C19.8707 18.9826 20 18.3324 20 17.6758V9.00579C19.9996 8.18238 19.7958 7.37181 19.4068 6.64609C19.0177 5.92037 18.4555 5.30196 17.77 4.84579ZM8 20.6758V15.6758C8 15.4106 8.10536 15.1562 8.29289 14.9687C8.48043 14.7811 8.73478 14.6758 9 14.6758H11C11.2652 14.6758 11.5196 14.7811 11.7071 14.9687C11.8946 15.1562 12 15.4106 12 15.6758V20.6758H8ZM18 17.6758C18 18.4714 17.6839 19.2345 17.1213 19.7971C16.5587 20.3597 15.7956 20.6758 15 20.6758H14V15.6758C14 14.8801 13.6839 14.1171 13.1213 13.5545C12.5587 12.9919 11.7956 12.6758 11 12.6758H9C8.20435 12.6758 7.44129 12.9919 6.87868 13.5545C6.31607 14.1171 6 14.8801 6 15.6758V20.6758H5C4.20435 20.6758 3.44129 20.3597 2.87868 19.7971C2.31607 19.2345 2 18.4714 2 17.6758V9.00579C2.00146 8.51258 2.1245 8.02733 2.35823 7.59301C2.59196 7.15869 2.92917 6.7887 3.34 6.51579L8.34 3.17579C8.83194 2.84899 9.4094 2.67467 10 2.67467C10.5906 2.67467 11.1681 2.84899 11.66 3.17579L16.66 6.51579C17.0708 6.7887 17.408 7.15869 17.6418 7.59301C17.8755 8.02733 17.9985 8.51258 18 9.00579V17.6758Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
  props: {
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      color: this.isActive ? '#0095C5' : '#7f7f7f',
    };
  },
};
</script>
