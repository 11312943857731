<template>
  <ConnectAccount />
</template>

<script>
import ConnectAccount from '@/components/steps/ConnectAccount.vue';

export default {
  name: 'GuestWrapper',
  components: {
    ConnectAccount,
	},
}
</script>
