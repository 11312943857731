<template>
	<button
		:type="type"
		class="button text-blue-light bg-white font-jost-semibold font-bold"
		:class="[`${classes}`]"
		@click="handleClick"
	>
		{{ label }}
	</button>
</template>

<script>
export default {
	name: 'GeeqButton2Component',
	props: {
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		classes: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick() {
			// handle button click event
			this.$emit('onClick');
		},
	},
};
</script>

<style scoped>
.button {
	/* button styles */
  box-shadow: 0px 0px 50px 0px #A6DFFF;
  /* border: 6px solid #fff; */
  border-radius: 12px;
  /* padding: 10px; */
  height: 76px;
  padding: 20px;
  font-size: 20px;
  min-width: 400px;
  line-height: 5px;
  margin-top: 40px;
  font-weight: 700;
}
</style>
