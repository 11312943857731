<template>
	<button
		:type="type"
		class="button text-white font-jost-semibold bg-blue-light text-[14px] font-bold"
		:class="[`${classes}`]"
		@click="handleClick"
	>
		{{ label }}
	</button>
</template>

<script>
export default {
	name: 'GeeqButtonComponent',
	props: {
		type: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		classes: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick() {
			// handle button click event
			this.$emit('onClick');
		},
	},
};
</script>

<style scoped>
.button {
	/* button styles */
  box-shadow: 0px 0px 50px 0px #A6DFFF;
  border: 10px solid #fff;
  border-radius: 12px;
  height: 76px;
  padding: 20px;
  line-height: 5px;
  color: white;
}
/* @media only screen and (max-width: 1340px) {
  button {
    max-width: 600px;
    min-width: 70%;
    width: unset;
  }
} */
</style>
