<template>
  <div class="mobile choose_card min-h-[calc(100vh-70px)] pb-0">
		<!-- Page Heading -->
		<Heading2Component
			text="1. Choose image"
			class="mt-[20px] mb-[25px] !text-[32px] !leading-[40px] font-jost-black !font-black !text-blue-dark"
		/>
		<!-- /Page Heading -->

		<div class="surprise-section m-auto mb-[30px] pb-[20px] flex justify-center items-baseline border-b border-[#CDEDFF]">

		<!-- Switch to show / hide unavailable items in the list -->
			<RoundedSwitchComponent
				:value="hideUnavailable"
				@onInput="changeHideUnavailableOption"
				label="Hide unavailable"
				class="mt-[20px]"
			/>
		<!-- /Switch to show / hide unavailable items in the list -->
		</div>

		<!-- NFT file gallery section -->
    <FileGallery
			classes="image_gallery_wrapper_mobile m-auto !px-0"
      :isHidden="hideUnavailable"
      @selectFile="handleSelectFile"
		/>
		<!-- /NFT file gallery section -->
    <div class="bg-white sticky bottom-0 w-full pt-[10px] pb-[40px] px-[20px]">
      <span class="btn-label mr-[30px] text-[18px] font-jost-regular leading-[26px] text-blue-dark inline-block">
        Can't decide?
      </span>
      <ButtonComponent
        @onClick="surprise"
        label="SURPRISE ME!"
        type="button"
        classes="surprise_btn font-jost-bold transition-all rounded-[10px] text-blue-light !font-bold
        hover:border-[#00adff] !py-[5px] px-[36px] mt-[10px] !text-[14px] h-[46px] w-[96%] !leading-[32px]"
      />
    </div>
	</div>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Heading2Component from '@/components/common/Heading2Component.vue';
import FileGallery from '@/components/FileGallery.vue';
import RoundedSwitchComponent from '@/components/common/RoundedSwitchComponent.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import api from '@/helpers/api';
import { mapState } from 'vuex';

export default {
	name: 'ChooseCardFileMobile',
	components: {
		Heading2Component,
		FileGallery,
		RoundedSwitchComponent,
    ButtonComponent,
	},
  computed: {
    ...mapState(['currentStep', 'passedSteps', 'selectedFile']),
  },
	data() {
    return {
			hideUnavailable: false,
    };
  },
  created() {
    this.$store.dispatch('resetUsersProgress');
  },
  mounted() {
    window.scrollTo(0, 0);
  },
	methods: {
		// Change the value of the hideUnavailable option
		changeHideUnavailableOption(val) {
			this.hideUnavailable = val;
		},
		// Handle click on the "Surprise me!" button
		surprise() {
      if (this.selectedFile) {
        this.$store.dispatch('freeImage', this.selectedFile.id);
        this.selectedFile = null;
      }

      api.getSurprise()
      .then((res) => {
        this.$store.commit('SET_SELECTED_FILE', res.data);
        this.$store.commit('SET_PASSED_STEPS', [...this.passedSteps, this.currentStep]);
        this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
      });
		},
    handleSelectFile(file) {
      if (this.selectedFile) {
        const isSameFile = this.selectedFile.id === file.id;
        this.$store.dispatch('freeImage', this.selectedFile.id);
        this.selectedFile = null;
        if (isSameFile) return;
      }

      api.selectFile(file.id)
      .then(() => {
        this.$store.commit('SET_SELECTED_FILE', file);
        this.$store.commit('SET_PASSED_STEPS', [...this.passedSteps, this.currentStep]);
        this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
      })
      .catch((err) => {
        const msg = err.response.data.details;
        toast.error(msg, {
          position: "bottom-right",
          duration: 2000,
          timeout: 2000,
        });
      });
    },
	},
};
</script>
<style>
 .mobile.choose_card .file_gallery {
    gap: 10px;
  }
  .mobile.choose_card .image_gallery_wrapper_mobile .card_item {
      width: unset !important;
      width: 161px !important;
      height: 186px !important;
      max-width: 161px !important;
      min-width: 161px !important;
      min-height: 186px !important;
      max-height: 186px !important;
      padding: 10px !important;
  }
  .mobile.choose_card .image_gallery_wrapper_mobile .card_item img {
      width: 141px;
      min-height: 116px;
      max-height: 116px;
      border-radius: 12px;
  }
  .mobile.choose_card .image_gallery_wrapper_mobile .card_item h3 {
      margin-top: 10px;
      line-height: 20px;
  }
  .surprise_btn {
    box-shadow: 0px 0px 50px 0px #A6DFFF;
  }
  .image_gallery_wrapper_mobile {
    justify-items: center;
    min-width: 320px;
    max-width: 550px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  @media only screen and (max-width: 767px) {
    .surprise-section {
      width: 100%;
    }
    .image_gallery_wrapper_mobile {
    justify-items: center;
    min-width: 320px;
    max-width: 550px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
  }
    .surprise-section {
      align-items: center;
    }
    .surprise-section .btn-label {
      margin-bottom: 10px;
    }
  }
</style>
