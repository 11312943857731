<template>
  <section class="px-[2.5%] min-h-[calc(100vh-74px)] w-full">
		<!-- Form to request minting of NFT -->
		<Heading2Component
    class="leading-[40px] !text-[32px] !font-black font-jost-black mt-[38px] mb-[38px] !text-blue-dark"
    text="3. Submit" />
		<div class="box px-[20px] pt-[30px] pb-[90px] m-auto text-center">
      <div class="flex flex-col items-center justify-center">
        <img class="h-[52px]" src="../../../public/images/submit_icon.png" />
        <p class="text-blue-dark mt-[19px] text-[18px] leading-[26px]">
          Almost there! Click the button below to send the transaction
          to the blockchain and mint your card as an NFT.
        </p>
      </div>
			<GeeqButtonComponent
        :class="{disabled: isLoading}"
        @onClick="handleClick"
        classes="xl:w-[623px] w-unset min-w-[80%] max-w-[623px] mt-[15px]"
        label="SIGN AND SEND"
        type="button"
      />
		</div>
		<!-- /Form to request minting of NFT -->
	</section>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Heading2Component from '@/components/common/Heading2Component.vue';
import GeeqButtonComponent from '@/components/common/GeeqButtonComponent.vue';
import { mapState } from 'vuex';
import api from '@/helpers/api';

export default {
	name: 'SignAndSendCardMobile',
	components: {
		Heading2Component,
		GeeqButtonComponent,
	},
  computed: {
    ...mapState(['currentStep', 'passedSteps', 'selectedFile', 'metaData', 'currentUser']),
  },
	data() {
		return {
			// Your data properties here
      isLoading: false,
		};
	},
  mounted() {
    window.scrollTo(0, 0);
  },
	methods: {
		// Handle click event to emit navigateTo "success" page.
		handleClick() {
      if (this.isLoading) return;
      this.isLoading = true;

      const data = {
        userId: this.currentUser.id,
        imageId: this.selectedFile.id,
        meta: Object.values(this.metaData),
      }

      api.submitData(data)
      .then((res) => {
        const { result } = res.data;
        this.$store.dispatch('getNft', result.id);
      })
      .catch((err) => {
        this.isLoading = false;

        let msg = err.response.data.error;

        if (err.response) {
          if (err.response.status === 500) {
            msg = "We are currently experiencing high traffic. Please try your request again in a little while.";
          }
        }
        toast.error(msg, {
          position: "bottom-right",
          duration: 2000,
          timeout: 2000,
        });
      })
		},
	},
};
</script>
<style scoped>
.box {
  box-shadow: 0px 17px 34px 0px #0000001A;
  background: #FFFFFF;
  width: 950px;
  height: 340px;
}
/* Your CSS styles here */
.box {
  box-shadow: 0px 17px 34px 0px #0000001A;
  background: #FFFFFF;
  width: 95%;
  border-radius: 10px;
}
@media only screen and (max-width: 940px) {
  .box {
    max-width: unset;
  }
}
</style>
