<template>
  <template v-if="currentUser">
    <AuthWrapperMobile />
  </template>
  <template v-else>
    <GuestWrapperMobile />
  </template>
</template>

<script>
import AuthWrapperMobile from '@/components/wrappers/AuthWrapperMobile.vue';
import GuestWrapperMobile from '@/components/wrappers/GuestWrapperMobile.vue';
import { mapState } from 'vuex';

export default {
  name: 'HomeMobileView',
  components: {
    AuthWrapperMobile,
    GuestWrapperMobile,
  },
  computed: {
    ...mapState(['currentStep', 'passedSteps', 'currentUser']),
  },
  // Your JavaScript code goes here
  data() {
    return {
    };
  },
}
</script>

<style scoped>
/* Your CSS styles go here */
</style>
