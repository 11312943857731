<template>
	<section class="pt-[55px]">
		<div
    class="form_stepper bg-white p-[20px] rounded-[12px] relative m-auto">
      <div class="step-container flex justify-between relative m-auto w-full absolute left-0">
        <ul class="list-unstyled multi-steps">
          <li class="relative" :class="{'is-active':currentStep===1, 'is-passed': isStepPassed(1)}">
						<span class="absolute text-[18px] left-[5px] top-[60px] text-[#333333]">
							Choose Image
						</span>
					</li>
          <li class="relative" :class="{'is-active':currentStep===2, 'is-passed': isStepPassed(2)}">
						<span class="absolute text-[18px] left-[5px] top-[60px] text-[#333333]">
							Add Labels
						</span></li>
          <li class="relative" :class="{'is-active':currentStep===3, 'is-passed': isStepPassed(3)}">
						<span class="absolute text-[18px] left-[5px] top-[60px] text-[#333333]">
							Submit
						</span></li>
        </ul>
      </div>
    </div>
	</section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'StepsComponent',
    computed: {
        ...mapState(['currentStep', 'passedSteps']),
    },
    methods: {
        nextStep() {
            // Move to the next step
            this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
        },
        prevStep() {
            // Move to the previous step
            this.$store.commit('SET_CURRENT_STEP', this.currentStep - 1);
        },
        isStepPassed(step) {
          // Check if the step is passed
          return this.passedSteps.includes(step);
        },
    },
};
</script>
<style lang="css" scoped>
/*form multistep bar styles*/

.form_stepper {
  box-shadow: 0px 4px 40px 0px #0000001F;
  width: 960px;
  height: 121px;
}
.multi-steps > li {
  min-width: 200px;
}
.multi-steps li {
display: table-cell;
}
.multi-steps li:last-child {
  text-align: right !important;
  width: 70px;
}
.multi-steps > li:last-child {
  min-width: 30px;
}
.multi-steps > li:not(:last-child).is-active:before,
.multi-steps > li.is-active ~ li:not(:last-child):before {
content: counter(stepNum);
font-weight: 700;
}
.multi-steps > li.is-passed:after {
  background-color: #0095C5;
}
.multi-steps > li.is-active ~ li:after {
background-color: #CDEDFF;
}
.multi-steps {
display: table;
table-layout: inherit;
width: 100%;
}
.multi-steps > li {
counter-increment: stepNum;
text-align: center;
display: table-cell;
position: relative;
color: #0095C5;
}
.multi-steps > li:before {
content: counter(stepNum);
display: block;
background-color: #0095C5;
color: #fff;
width: 28px;
height: 28px;
line-height: 28px;
text-align: center;
font-weight: bold;
border-width: 1px;
border-style: solid;
border-color: #0095C5;
border-radius: 50%;
}

.multi-steps > li:after {
content: "";
height: 2px;
width: 365px;
background-color: #CDEDFF;
position: absolute;
top: 15px;
right: 0;
z-index: 1;
}
.multi-steps > li:last-child:after {
display: none;
}
.multi-steps > li.is-active:before,
.multi-steps > li.is-passed:before {
background-color: #0095C5;
border-color: #0095C5;
color: #fff;
}
.multi-steps > li.is-active:before {
  background-color: #fff;
  color: #0095C5;
}
.multi-steps > li.is-active ~ li {
color: #fff;
}
.multi-steps > li.is-active ~ li:before {
background-color: #CDEDFF;
border-color: #CDEDFF;
color: #fff;
}
.multi-steps > li::before {
margin-left: 0;
}
.multi-steps > li:after {
left: 42px;
}
@media only screen and (max-width: 1399px) {
  .form_stepper {
    box-shadow: 0px 4px 40px 0px #0000001F;
    max-width: 850px;
    width: unset;
    height: 121px;
  }
  .multi-steps > li:after {
    width: 315px;
    }
  }
@media only screen and (max-width: 1200px) {
  .form_stepper {
    box-shadow: 0px 4px 40px 0px #0000001F;
    max-width: 750px;
    width: unset;
    height: 121px;
  }
  .multi-steps > li:after {
    width: 265px;
    }
  }
  @media only screen and (max-width: 1040px) {
   .form_stepper {
    max-width: 650px;
   }
   .multi-steps > li:after {
    width: 215px;
  }
  }
  @media only screen and (max-width: 940px) {
   .form_stepper {
    max-width: 490px;
   }
   .multi-steps > li:after {
    width: 146px;
  }
  }
</style>
