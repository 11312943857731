<template>
  <!-- HTML template for the component -->
  <div>
    <RouterLink :to="{name: 'welcome'}">
      <img src="/images/geeq_logo.svg" class="max-w-[105px]" />
    </RouterLink>
  </div>
</template>

<style scoped>
/* Component-specific styles go here */
</style>
