<template>
  <div class="side_menu sticky top-0 py-[35px] h-full !bg-white">
    <GeeqDataLogoComponent class="pl-[37px]" />
    <!-- Menu content goes here -->
    <div class="mt-[35px]"></div>
    <RouterLink to="/home" @click.prevent="goToHomePage" class="menu_item text-[18px] leading-[26px] flex gap-[16px] items-center mb-[40px] pl-[37px] relative">
      <HomeIcon :isActive="checkIfRouteIsActive('home')" />
      Home
    </RouterLink>
    <a :href="blockExplorerHost" target="_blank" class="menu_item text-[18px] leading-[26px] flex gap-[16px] items-center mb-[40px] pl-[37px] relative">
      <SearchIcon :isActive="false" />
      Find your card
    </a>
    <a href="https://geeq.io/tag/NFT" class="menu_item text-[18px] leading-[26px] flex gap-[16px] items-center mb-[40px] pl-[37px] relative">
      <CommentIcon :isActive="false" />
      Learn more
    </a>
  </div>
</template>

<script>
import GeeqDataLogoComponent from '@/components/common/GeeqDataLogoComponent.vue';
import HomeIcon from '@/components/svg/HomeIcon.vue';
import SearchIcon from '@/components/svg/SearchIcon.vue';
import CommentIcon from '@/components/svg/CommentIcon.vue';
import { mapState } from 'vuex';

export default {
  name: 'SideMenuComponent',
  components: {
    GeeqDataLogoComponent,
    HomeIcon,
    SearchIcon,
    CommentIcon,
  },
  computed: {
    ...mapState(['blockExplorerHost']),
  },
  methods: {
    checkIfRouteIsActive(route) {
      return this.$route.name === route;
    },
    goToHomePage() {
      // console.log(route);
      // this.$router.push({ name: route, params: { uniqueKey: Date.now() }});
      this.$store.commit('SET_CURRENT_STEP', 1);
    }
  },
};
</script>

<style scoped>
  .side_menu {
    box-shadow: 0px 2px 100px 0px #0000001A;
  }
  .router-link-active {
    color: #3D94C3;
  }
  .router-link-active:before {
    content: url('../../public/images/focus.png');
    position: absolute;
    left: 0;
    top: -20px;
    display: inline-block;
    height: auto;
  }
</style>
