import axios from 'axios';
import store from '@/store';
import { deleteFromStorage } from '@/helpers/sessionStorage';

// create an axios instance with default options
const http = axios.create({ baseURL: window.location.origin });
// const http = axios.create({ baseURL: 'http://localhost:3000' });

 // Define Middleware for axios request
 http.interceptors.request.use(
  (config) => {
    if (sessionStorage.getItem('token')) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Token = `${sessionStorage.getItem('token')}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Define Middleware for axios response
http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.commit('LOGOUT');
      deleteFromStorage('token');
    }
    return Promise.reject(error);
  },
);

export default {
  getAuthUser() {
    return http.get('/api/user');
  },
  getNftById(id: number) {
    return http.get(`/api/nft/${id}`);
  },
  getRedirectionUrl() {
    return http.get('/api/user/twitter');
  },
  getBlockExplorerHost() {
    return http.get('/api/system')
  },
  getSurprise() {
    return http.get('/api/image/surprise');
  },
  getFiles(params: any) {
    const { skip, limit, hide } = params;

    return http.get(`/api/image?skip=${skip}&limit=${limit}&hide=${hide}`);
  },
  selectFile(id: number) {
    return http.post(`/api/image/${id}`);
  },
  freeImage(id: number) {
    return http.post(`/api/image/unreserved/${id}`);
  },
  askToRebuild() {
    return http.post('/api/image/rebuild?admin=admin');
  },
  submitData(data: any) {
    return http.post('/api/nft', data);
  }
};
