<template>
	<section class="pt-[55px]">
		<div
    class="form_stepper bg-white rounded-[12px] relative m-auto">
      <div class="step-container flex justify-between m-auto w-full absolute left-[20px] top-[8px]">
        <ul class="list-unstyled multi-steps">
          <li class="relative" :class="{'is-active':currentStep===1, 'is-passed': isStepPassed(1)}">
					</li>
          <li class="relative" :class="{'is-active':currentStep===2, 'is-passed': isStepPassed(2)}">
					</li>
          <li class="relative" :class="{'is-active':currentStep===3, 'is-passed': isStepPassed(3)}">
					</li>
        </ul>
      </div>
    </div>
	</section>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'StepsComponentMobile',
    computed: {
        ...mapState(['currentStep', 'passedSteps']),
    },
    methods: {
        nextStep() {
            // Move to the next step
            this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
        },
        prevStep() {
            // Move to the previous step
            this.$store.commit('SET_CURRENT_STEP', this.currentStep - 1);
        },
        isStepPassed(step) {
          // Check if the step is passed
          return this.passedSteps.includes(step);
        },
    },
};
</script>
<style lang="css" scoped>
/*form multistep bar styles*/

.form_stepper {
  box-shadow: 0px 4px 40px 0px #0000001F;
  width: 95%;
  height: 46px;
}
.multi-steps > li {
  min-width: 145px;
}
.multi-steps li {
display: table-cell;
}
.multi-steps li:last-child {
  text-align: right !important;
  width: 70px;
}
.multi-steps > li:last-child {
  min-width: 30px;
}
.multi-steps > li:not(:last-child).is-active:before,
.multi-steps > li.is-active ~ li:not(:last-child):before {
content: counter(stepNum);
font-weight: 700;
}
.multi-steps > li.is-passed:after {
  background-color: #0095C5;
}
.multi-steps > li.is-active ~ li:after {
background-color: #CDEDFF;
}
.multi-steps {
display: table;
table-layout: inherit;
width: 100%;
}
.multi-steps > li {
counter-increment: stepNum;
text-align: center;
display: table-cell;
position: relative;
color: #0095C5;
}
.multi-steps > li:before {
content: counter(stepNum);
display: block;
background-color: #0095C5;
color: #fff;
width: 28px;
height: 28px;
line-height: 28px;
text-align: center;
font-weight: bold;
border-width: 1px;
border-style: solid;
border-color: #0095C5;
border-radius: 50%;
}

.multi-steps > li:after {
content: "";
height: 2px;
width: 365px;
background-color: #CDEDFF;
position: absolute;
top: 15px;
right: 0;
z-index: 1;
}
.multi-steps > li:last-child:after {
display: none;
}
.multi-steps > li.is-active:before,
.multi-steps > li.is-passed:before {
background-color: #0095C5;
border-color: #0095C5;
color: #fff;
}
.multi-steps > li.is-active:before {
  background-color: #fff;
  color: #0095C5;
}
.multi-steps > li.is-active ~ li {
color: #fff;
}
.multi-steps > li.is-active ~ li:before {
background-color: #CDEDFF;
border-color: #CDEDFF;
color: #fff;
}
.multi-steps > li::before {
margin-left: 0;
}
.multi-steps > li:after {
left: 42px;
}

  @media only screen and (max-width: 767px) {
   .form_stepper {
    max-width: 90%;
    min-width: 320px;
    height: 46px;
    padding: 20px;
   }
   .multi-steps > li:after {
    width: 80%;
  }
  }
  @media only screen and (max-width: 650px) {
   .multi-steps > li:after {
    width: 75%;
  }
  }
  @media only screen and (max-width: 500px) {
   .multi-steps > li:after {
    width: 68%;
  }
  }
  @media only screen and (max-width: 390px) {
   .form_stepper {
    max-width: 85%;
    min-width: 320px;
    height: 46px;
    padding: 20px;
   }
   .multi-steps > li:after {
    width: 60%;
  }
  .multi-steps li {
    min-width: 126px;
  }
  }
</style>
