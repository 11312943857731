<template>
	<section class="main_wrapper relative min-h-[100vh] max-h-max-content">
    <div class="relative z-20 m-auto mt-[120px] flex flex-col items-center justify-between                                                                                                        ">
      <div class="circle flex flex-col items-center justify-center">
        <div class="card_item cursor-pointer bg-[#fff] p-[20px] rounded-[12px]">
          <img class="w-full max-h-[82%]" :src="selectedFile.url" />
          <h3 class="text-left mt-[22px] text-[18px] text-gray-main text-ellipsis font-jost-regular leading-[26px]">{{ metaData.name }}</h3>
        </div>
        <h1 class="font-jost-black !font-black text-[30px] !leading-[40px] text-blue-dark mt-[40px]">
          Congratulations! <br />
          Your Holiday card has been <br />
          minted as a Geeq NFT!
        </h1>
      </div>
    </div>
    <div class="relative mt-[45px] z-20">
      <!-- Heading -->
      <Heading2Component class="!text-white !font-black font-jost-black !text-[32px] !leading-[46px]" text="Share it with the world!" />
      <!-- /Heading -->
      <div class="mt-[25px]">
        <div class="flex items-center gap-[10px] max-w-max mx-auto pb-[25px] border-b border-blue-light">
           <!-- Button -->
          <GeeqButton2Component
            id="twitterShareButton"
            classes="geeq-btn-share uppercase !mt-0 !text-[14px]"
            @onClick="shareViaTwitter"
            type="button"
            label="SHARE"
          />
          <button @click="copy"><img src="../../../public/images/copy.svg" /></button>
          <!-- /Button -->
        </div>
        <!-- <a href="https://twitter.com/intent/tweet?text=Check%20NFT&url=https%3A%2F%2Fblkexp.geeq-test.io" target="_blank">Post to Twitter</a> -->
      </div>

      <div class="m-auto flex justify-center gap-[20px] mt-[25px] mb-[35px]">
        <ExternalLinkComponent
          classes="external-link uppercase h-[46px] min-w-[264px] text-white rounded-[30px] transition-all
          bg-blue-dark py-[6px] px-[20px] !text-[14px] leading-[31px]"
          label="Check out the NFT"
          :to="blExpNfturl"
        />
        <ExternalLinkComponent
          classes="external-link uppercase h-[46px] min-w-[264px] text-white rounded-[30px] transition-all
          bg-blue-dark py-[6px] px-[20px] !text-[14px] leading-[31px]"
          label="See mint transaction"
          :to="blExpNftTxurl"
        />
      </div>
    </div>
  <SnowComponent class="!absolute" />

	</section>
</template>

<script>
import { copyToClipboard } from '@/helpers/common';
import GeeqButton2Component from '@/components/common/GeeqButton2Component.vue';
import Heading2Component from '@/components/common/Heading2Component.vue';
import ExternalLinkComponent from '@/components/common/ExternalLinkComponent.vue';
import SnowComponent from '@/components/common/SnowComponent.vue';
import { mapState } from 'vuex';

export default {
	name: 'CongratulationsComponent',
	components: {
    GeeqButton2Component,
    Heading2Component,
    ExternalLinkComponent,
    SnowComponent,
	},
	data() {
		return {
      file: {
        path: 'https://picsum.photos/seed/picsum/200/300',
        name: 'Name of NFT card',
        description: 'This is the first file',
      },
			socials: [{ name: 'X' }, { name: 'Facebook' }, { name: 'Instagram' }, { name: 'Discord' }],
      secondUrl: 'https://mint.geeq.io',
		};
	},
  computed: {
    ...mapState(['nft', 'blockExplorerHost', 'selectedFile', 'metaData']),
    blExpNftTxurl() {
      if (!this.blockExplorerHost || !this.nft) return '';
      console.log('this.nft', this.nft);
      return `${this.blockExplorerHost}/nft-transaction/${this.nft.result.refHash}?image=${this.selectedFile.id}`;
    },
    blExpNfturl() {
      if (!this.blockExplorerHost || !this.nft) return '';
      console.log('this.nft', this.nft);
      return `${this.blockExplorerHost}/nft/${this.nft.result.refHash}?image=${this.selectedFile.id}`;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    copy() {
      if (!this.blExpNfturl) return;
      copyToClipboard(this.blExpNfturl);
    },
    shareViaTwitter() {
      if (!this.blExpNfturl) return;

      const text = `I've just created an NFT Holiday card using Geeq technology. Check it out at ${this.blExpNfturl} or create yours at ${this.secondUrl}`; // replace with your actual text
      const encodedText = encodeURIComponent(text);
      const twitterURL = `https://twitter.com/intent/tweet?text=${encodedText}`;

      window.open(twitterURL, '_blank');
    }
  },
};
</script>

<style scoped>
  .main_wrapper {
    overflow: hidden;
    background: url('../../../public/images/Background_congrats_page.svg') no-repeat;
    background-position: center top;
    background-size: cover;
  }
  section .page-bg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .card_item {
    width: 225px !important;
    height: 270px !important;
    box-shadow: 0px 4px 40px 0px #0000001F;
    position: absolute;
    top: -80px;
  }
  .circle {
    background: url('../../../public/images/Circle.svg') no-repeat;
    background-size: cover;
    min-width: 491px;
    min-height: 491px;
  }
</style>
<style>
.geeq-btn-share {
  width: 495px !important;
  height: 46px !important;
}
 @media only screen and (max-width: 940px) {
   .geeq-btn-share {
    width: 420px !important;
   }
   .external-link {
    min-width: 225px;
   }
  }
</style>
