<template>
  <component :is="layout">
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
import GuestLayout from '@/views/Layouts/GuestLayout';
import HomeLayout from '@/views/Layouts/HomeLayout';
import HomeMobileLayout from '@/views/Layouts/HomeMobileLayout';

export default {
  name: 'layout-wrapper',
  components: {
    GuestLayout,
    HomeLayout,
    HomeMobileLayout,
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data() {
    return {
      initial: true,
      window: {
        width: 0,
      },
    };
  },
  computed: {
    route() {
      return this.$route;
    },
    layout() {
      return `${this.route.meta.layout}-layout`;
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
    },
  },
};
</script>

<style lang="css"></style>
