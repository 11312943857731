<template>
	<div>
		<!-- AddCardMessage content goes here -->
    <section class="wrapper m-auto flex flex-col items-center justify-center">
      <Heading2Component
      v-if="isSubmitted"
      text="2. Check your message"
      class="!text-[32px] !leading-[40px] !w-[100%] !max-w-[335px] mt-[38px] font-jost-black !font-black !text-blue-dark"
      />
      <Heading2Component
      v-else
      text="2. Add a message to your card"
      class="!text-[32px] !leading-[40px] !w-[100%] !max-w-[335px] mt-[38px] font-jost-black !font-black !text-blue-dark"
      />
      <div class="w-full">
        <section class="mt-[34px] min-h-[calc(100vh-240px)] w-full m-auto">
        <div class="form-wrapper flex justify-between items-start gap-[30px]">
          <div class="empty-container"></div>
          <div class="form_container_mobile p-[15px]">
            <div class="w-full h-[420px] img-container">
              <img class="rounded-[40px] object-contain w-full h-full" :src="getCardImgUrl()" />
            </div>
            <form class="mt-[35px] max-h-[237px]">
              <div class="mb-[15px] text-left" v-if="isSubmitted">
                <p
                class="text-white font-jost-medium text-[20px] leading-[24px] text-ellipsis"
                >
                <span class="!text-white">{{ metaData.name  }}</span>
                </p>
              </div>
              <div class="mb-[15px]" v-else>
                <label
                for="large-input"
                class="flex text-black font-jost-medium text-[20px] px-[15px] py-[12px] text-left bg-[#c5c5c5]
                    border border-[#F0F0F0] focus:ring-blue-500 focus:border-blue-500"
                >
                  <input
                    @input="updateMetadata($event, 'name')"
                    @paste="updateMetadata($event, 'name')"
                    :value="metaData.name"
                    type="text"
                    id="large-input"
                    class="text-ellipsis font-jost-medium w-full text-[20px] focus:ring-blue-500 focus:border-blue-500"
                    autocomplete="off"
                    readonly
                  >
                </label>
              </div>
              <div class="mb-[15px] text-left" v-if="isSubmitted">
                <p
                class="text-white font-jost-medium text-[20px] leading-[24px] text-ellipsis"
                >
                  From: {{ metaData.from }}
                </p>
              </div>
              <div class="mb-[15px]" v-else>
                <label
                  for="large-input"
                  :class="{grid: !metaErrors.from, flex: metaErrors.from}"
                  class="message-from grid-cols-[13%,84%] text-black font-jost-medium text-[20px] px-[15px] py-[12px] text-left bg-[#c5c5c5]
                    border border-[#F0F0F0] focus:ring-blue-500 focus:border-blue-500"
                >
                <input
                  @input="updateMetadata($event, 'from')"
                  @paste="updateMetadata($event, 'from')"
                  :placeholder=metaErrors.from
                  class="text-ellipsis text-[20px] h-[24px] w-[100%]"
                  v-if="metaErrors.from" />
                  <template v-else>
                    <span>
                      From: &nbsp;
                    </span>
                    <input
                      @input="updateMetadata($event, 'from')"
                      @paste="updateMetadata($event, 'from')"
                      :value="metaData.from"
                      type="text"
                      id="large-input"
                      class="text-ellipsis text-[20px] focus:ring-blue-500 focus:border-blue-500"
                      readonly
                      autocomplete="off"
                    >
                  </template>
                </label>
              </div>
              <div class="mb-[15px] text-left" v-if="isSubmitted">
                <p
                class="text-white font-jost-medium text-[20px] leading-[24px] text-ellipsis"
                >
                 To: {{ metaData.to }}
                </p>
              </div>
              <div class="mb-[15px]" v-else>
                <label
                  for="large-input"
                  :class="{grid: !metaErrors.to, flex: metaErrors.to}"
                  class="grid-cols-[7%,90%] text-black font-jost-medium text-[20px] px-[15px] py-[12px] text-left bg-[#c5c5c5]
                    border border-[#F0F0F0] focus:ring-blue-500 focus:border-blue-500"
                >
                <input
                  @input="updateMetadata($event, 'to')"
                  @paste="updateMetadata($event, 'to')"
                  :placeholder=metaErrors.to
                  class="text-ellipsis text-[20px] h-[24px] w-[100%]"
                  v-if="metaErrors.to" />
                  <template v-else>
                    <span>
                      To:
                    </span>
                    <input
                      @input="updateMetadata($event, 'to')"
                      @paste="updateMetadata($event, 'to')"
                      :value="metaData.to"
                      type="text"
                      id="large-input"
                      class="text-ellipsis text-[20px] focus:ring-blue-500 focus:border-blue-500"
                      autocomplete="off"
                    >
                  </template>
                </label>
              </div>
              <div class="text-left" v-if="isSubmitted">
                <p
                class="text-white font-jost-medium text-[20px] leading-[32px] text-ellipsis"
                >
                  Message: {{ metaData.message }}
                </p>
              </div>
              <div class="mb-[15px]" v-else>
                <label
                    for="large-input"
                    :class="{grid: !metaErrors.message, flex: metaErrors.message}"
                    class="message-metadata grid-cols-[23%,74%] text-black font-jost-medium text-[20px] px-[15px] py-[12px] text-left bg-[#c5c5c5]
                    border border-[#F0F0F0] focus:ring-blue-500 focus:border-blue-500"
                  >
                  <input
                  @input="updateMetadata($event, 'message')"
                  @paste="updateMetadata($event, 'message')"
                  :placeholder=metaErrors.message
                  class="text-ellipsis text-[20px] h-[24px] w-[100%]"
                  v-if="metaErrors.message" />
                  <template v-else>
                    <span>
                      Message:
                    </span>
                    <input
                      @input="updateMetadata($event, 'message')"
                      @paste="updateMetadata($event, 'message')"
                      :value="metaData.message"
                      type="text"
                      id="large-input"
                      class="text-ellipsis text-[20px] focus:ring-blue-500 focus:border-blue-500"
                      autocomplete="off"
                    >
                  </template>
                </label>
              </div>
              <div class="w-full text-center mt-[48px]" v-if="isSubmitted">
                <button
                 class="w-[90px] h-[26px] text-[14px] text-white border border-white rounded-[12px] py-[3px] px-[10px]"
                 @click="changeIsSubmitted(false)"
                 >Edit</button>
              </div>
            </form>
          </div>
          <div class="timer-container flex flex-col justify-center items-start relative w-[100%] max-w-[335px]">
            <LinearProgressComponent />
            <div
              class="tooltip_info_mobile absolute w-full bg-white rounded-[16px] py-[12px] px-[24px] w-[236px] h-fit">
              <img class="absolute" src="../../../public/images/Arrow.png" />
              <p class="font-jost-regular text-[14px] leading-[20px] text-blue-dark">
                You have 2 minutes to mint this image as an NFT. After that, it will go back into the pool and you will have to choose an image again.
              </p>
            </div>
          </div>
        </div>
        </section>
      </div>
      <div v-if="!isSubmitted">
      <!-- NFT file gallery section -->
        <p class="text-[18px] leading-[26px] text-blue-dark mt-[135px] w-[100%] max-w-[335px]">
				Don't like this one? Choose another from the gallery below
			</p>
      <p class="text-[18px] leading-[26px] text-blue-dark font-jost-semibold">or</p>
      <ButtonComponent
					@onClick="surprise"
					label="Surprise me again!"
          type="button"
					classes="surprise_btn !mt-[20px] uppercase !font-bold font-jost-bold transition-all rounded-[10px] !text-blue-dark font-bold
          hover:border-[#00adff] !py-[5px] px-[36px] !text-[14px] !w-[100%] !max-w-[335px] !h-[48px] !leading-[32px]"
				/>
      </div>

    </section>
    <FileGallery
      v-if="!isSubmitted"
			classes="image_gallery_wrapper2_mobile m-auto !px-0 py-[30px]"
      :isHidden="true"
      @selectFile="handleSelectFile"
		/>
		<!-- /NFT file gallery section -->
    <div v-if="isSubmitted" class="bg-white sticky bottom-0 w-full pt-[20px] pb-[40px] px-[20px] mt-[135px]">
      <GeeqButtonComponent
      @onClick="handleClick"
      classes="geeq-btn-continue text-white !w-[100%] !max-w-[335px] !p-0"
      label="CONTINUE"
      :class="{disabled: isSubmitDisabled}"
      type="button"
      />
    </div>
    <div v-else class="bg-white sticky bottom-0 w-full pt-[20px] pb-[40px] px-[20px]">
      <GeeqButtonComponent
        @onClick="changeIsSubmitted(true)"
        classes="geeq-btn-submit text-white !w-[100%] !max-w-[335px] !px-0"
        :class="{disabled: isSubmitDisabled}"
        label="SUBMIT"
        type="button"
        />
    </div>
	</div>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Heading2Component from '@/components/common/Heading2Component.vue';
import GeeqButtonComponent from '@/components/common/GeeqButtonComponent.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import LinearProgressComponent from '@/components/common/LinearProgressComponent.vue';
import FileGallery from '@/components/FileGallery.vue';
import { mapState } from 'vuex';
import api from '@/helpers/api';

export default {
	name: 'AddCardMessageMobile',
	components: {
		Heading2Component,
		GeeqButtonComponent,
    ButtonComponent,
    LinearProgressComponent,
    FileGallery,
	},
  computed: {
    ...mapState(['currentStep', 'passedSteps', 'selectedFile', 'metaData', 'currentUser']),
    isSubmitDisabled() {
      return this.metaErrors.name !== '' || this.metaErrors.from !== ''  || this.metaErrors.to !== '' || this.metaErrors.message !== '';
    },
  },
  watch: {
    selectedFile(val) {
      const newId = val ? val.id : '';
      this.$store.commit('SET_METADATA', {name: 'name', value: `Geeq Holiday NFT 2023 #${newId}`});
    },
  },
	data() {
		return {
			isSubmitted: false,
      metaMaxLength: 62,
      metaErrors: {
        name: '',
        from: '',
        to: '',
        message: '',
      },
		};
	},
  mounted() {
    window.scrollTo(0, 0);
    this.$store.commit('SET_METADATA', {name: 'name', value: `Geeq Holiday NFT 2023 #${this.selectedFile.id}`});
    this.$store.commit('SET_METADATA', {name: 'from', value: this.currentUser.displayName});
  },
	methods: {
    updateMetadata(event, name) {
      const value = event.target.value;
      const data = { name, value };
      const encoder = new TextEncoder();

      // Encode the string to UTF-8 bytes
      const encodedString = encoder.encode(value);

      // Get the byte length
      const byteLength = encodedString.length;

      if (byteLength <= this.metaMaxLength) {
        this.metaErrors[name] = '';
        this.$store.commit('SET_METADATA', data);
      } else {
        this.metaErrors[name] = `Max length is ${this.metaMaxLength} characters`;
      }
      this.$forceUpdate()
    },
    surprise() {
      if (this.selectedFile) {
        this.$store.dispatch('freeImage', this.selectedFile.id);
        this.selectedFile = null;
      }

      api.getSurprise()
      .then((res) => {
        console.log(res.data);
        this.$store.commit('SET_SELECTED_FILE', res.data);
      });
		},
    getCardImgUrl() {
      return this.selectedFile.url || '../../../public/images/card-img-default.png';
    },
    changeIsSubmitted(submitted) {
      if (this.isSubmitDisabled) return;
      this.isSubmitted = submitted;
    },
		// AddCardMessage methods go here
		handleClick() {
      if (this.isSubmitDisabled) return;
      this.$store.commit('SET_PASSED_STEPS', [...this.passedSteps, this.currentStep]);
      this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
		},
    handleSelectFile(file) {
      if (this.selectedFile) {
        const isSameFile = this.selectedFile.id === file.id;
        this.$store.dispatch('freeImage', this.selectedFile.id);
        this.selectedFile = null;
        if (isSameFile) return;
      }

      api.selectFile(file.id)
      .then(() => {
        this.$store.commit('SET_SELECTED_FILE', file);
      })
      .catch((err) => {
        const msg = err.response.data.details;
        toast.error(msg, {
          position: "bottom-right",
          duration: 2000,
          timeout: 2000,
        });
      });
    },
	},
};
</script>

<style scoped>
/* AddCardMessage styles go here */
form * {
    font-family: 'Jost-Medium', sans-serif !important;
  }
input::placeholder {
  color: #D66339;
}
.form_container_mobile {
    background: #33AAD0;
    border: 1.93px solid;
    border-image-source: radial-gradient(34.94% 19.21% at 77.95% 86.99%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(180deg, #A8E5FF 0%, rgba(82, 62, 53, 0) 100%);
    width: 450px;
    border-radius: 50px;
    border: 1.93px;
    padding-bottom: 35px;
}
form label {
  width: 100%;
  height: 48px;
  padding: 10px, 30px, 10px, 30px;
  border-radius: 30px;
  gap: 10px;
  background: #E6F6FF;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  color: #2E4064;
}
form input {
  height: 24px;
  background: #E6F6FF;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: #2E4064;
}
form input:focus-visible {
  outline: none;
}
form span {
  color: #2E4064;
  line-height: 24px;
}
.surprise_btn {
    box-shadow: 0px 0px 50px 0px #A6DFFF;
  }
  .tooltip_info_mobile {
    box-shadow: 0px 4px 40px 0px #00000017;
    margin-top: 10px;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    right: 0;
    max-width: 335px;
  }
  .tooltip_info_mobile img {
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .snow_bg {
    position: absolute;
  }
</style>
<style>
  .image_gallery_wrapper2_mobile {
    justify-items: center;
  }
  .image_gallery_wrapper2_mobile .card_item {
    width: 160px !important;
    height: 186px !important;
  }
  .image_gallery_wrapper2_mobile .card_item h3 {
    margin-top: 10px !important;
  }
  .image_gallery_wrapper2_mobile {
    justify-items: center;
    max-width: 650px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-around;
    align-items: center;
  }
  .wrapper {
    max-width: 650px;
  }
  .empty-container {
    width: 200px;
    height: 200px;
  }
  @media only screen and (max-width: 530px) {
    .form_container_mobile {
      width: 390px !important;
    }
    .form_container_mobile .img-container {
      height: 355px !important;
    }
    .name-metadata {
      grid-template-columns: 56% 40% !important;
    }
    .message-metadata {
      grid-template-columns: 34% 61% !important;
    }
    .message-from {
      grid-template-columns: 18% 79% !important;
    }
  }
  @media only screen and (max-width: 430px) {
  .form_container_mobile {
    width: 310px !important;
  }
  .form_container_mobile .img-container {
    height: 280px !important;
  }
  .name-metadata {
      grid-template-columns: 74% 25% !important;
  }
  .message-from {
    grid-template-columns: 21% 75% !important;
  }
  }
</style>
