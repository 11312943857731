<template>
	<!-- Rounded switch -->
	<div class="text-left flex justify-start items-center gap-[17px] text-[20px] text-black">
		<label class="switch">
			<input type="checkbox" :val="value" @change="toggle">
			<span class="slider round"></span>
		</label>
    <span class="font-jost-regular text-[18px] leading-[26px] text-blue-dark">
      {{ label }}
    </span>
	</div>
	<!-- /Rounded switch -->
</template>

<script>
export default {
	name: 'RoundedSwitchComponent',
	props: {
		value: {
			type: Boolean,
			required: true,
		},
    label: {
      type: String,
      required: true,
    },
	},
	methods: {
		toggle() {
				this.$emit('onInput', !this.value);
		},
	},
};
</script>

<style scoped>
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 53px;
  min-width: 53px;
  height: 32px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
	border-radius: 16px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
	border-radius: 16px;
}

input:checked + .slider {
  background-color: #3375EE;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3375EE;
}

input:checked + .slider:before {
  -webkit-transform: translateX(19px);
  -ms-transform: translateX(19px);
  transform: translateX(19px);
}

/* Rounded sliders */
</style>
