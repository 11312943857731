<template>
	<div class="card_item bg-[#fff] p-[20px] rounded-[12px]" :class="{'is-unavailable': file.status}">
    <img class="w-full max-h-[82%] rounded-[12px]" :src="file.url" />
    <h3 class="text-ellipsis text-left mt-[22px] text-[18px] text-gray-main font-jost-regular leading-[26px]">{{ getFileName(file.name) }}</h3>
  </div>
</template>

<script>
export default {
	name: 'SingleFile',
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
  methods: {
    getFileName(name) {
      let str = name.replace(/Colander/g, "Calendar").replace(/calendar/g, "Calendar");

      return str;
    },
  },
};
</script>

<style scoped>
  /* Component styles go here */
  .card_item {
    box-shadow: 0px 4px 40px 0px #0000001F;
  }
  .is-unavailable {
    opacity: 0.9;
    filter: grayscale(1);
    cursor: not-allowed !important;
  }
</style>
