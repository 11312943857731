<template>
	<section id="gallery" class="file-gallery">
		<!-- If the list is empty -->
		<p
      v-if="files.length<1"
      class="text-center font-jost-semibold text-[20px] text-gray-dark px-[2.5%] mt-[50px]"
    >
      The gallery is empty..
    </p>
		<!-- /If the list is empty -->

		<div class="px-[2.5%]" :class="[`${classes}`]">
			<!-- Loops over Gallery list and renders each single Gallery item -->
			<SingleFile v-for="file in files" :key="file.id" :file="file" class="!cursor-default" />
			<!-- /Loops over Gallery list and renders each single Gallery item -->
		</div>
	</section>
</template>

<script>
import SingleFile from '@/components/SingleFile.vue';

export default {
	name: 'CardGallery',
	components: {
		SingleFile,
	},
	props: {
		files: {
			type: Array,
			required: true,
		},
		classes: {
			type: String,
			default: '',
		},
	},
};
</script>
<style lang="scss" scoped>
.file_gallery {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  text-align: center;
  justify-content: center;
  justify-items: center;
  max-width: 1360px;
  margin: auto;
}
.file_gallery .card_item {
  width: 410px;
  min-height: 385px;
  max-height: 385px;
}
@media only screen and (max-width: 1400px) {
  .file_gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.file_gallery .card_item {
  width: unset;
  max-width: 410px;
  min-width: 350px;
  min-height: 320px;
  max-height: 320px;
}
}
@media only screen and (max-width: 1150px) {
  .file_gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
}
</style>
