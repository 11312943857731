<template>
  <div class="main_section layout_grid">
    <SideMenuComponent />
    <slot />
  </div>
</template>

<script>
import SideMenuComponent from '@/components/SideMenuComponent.vue';

export default {
  name: 'HomeLayout',
  components: {
    SideMenuComponent,
  },
};
</script>

<style scoped>
/* Your CSS styles here */
.main_section {
    background-image: url('../../../public/images/snow_l.png'),
    url('../../../public/images/snow_r.png'), url('../../../public/images/bg_card.png');
    background-position: left bottom, right bottom, center bottom; /* Adjust as needed */
    background-repeat: no-repeat, no-repeat, no-repeat; /* Adjust as needed */
    background-size: auto, auto, auto;
}
</style>
