<template>
	<a
		:href="to"
		class="link text-[24px] px-[20px] py-[17px]"
		:class="[`${classes}`]"
		@click="handleClick"
    target="_blank"
	>
		{{ label }}
</a>
</template>

<script>
export default {
	name: 'ButtonComponent',
	props: {
		to: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		classes: {
			type: String,
			default: '',
		},
	},
	methods: {
		handleClick() {
			// handle button click event
			this.$emit('onClick');
		},
	},
};
</script>

<style scoped>
.link {
	/* button styles */
  font-weight: 700;
}
</style>
