<template>
  <div class="home relative overflow-hidden">
    <SnowComponent />
    <div class="relative m-auto flex flex-col items-center justify-between">
    </div>
    <section class="gallery-section-wrapper relative">
    <img class="bg_top" src="../../public/images/bg_top_web.svg" alt="Poster" />

       <!-- Page Top section -->
      <IntroComponent @navigateTo="handleClick" class="" />
      <!-- /Page Top section -->

      <div class="mt-[40px]">
        <!-- File gallery section -->
        <CardGallery :files="files" classes="file_gallery mt-[20px]" />
        <!-- /File gallery section -->
      </div>
    </section>
     <!-- Click to request to start minting process -->
     <RequestToMint @navigateTo="handleClick" />
      <!-- /Click to request to start minting process -->

    <!-- <img class="bg_bottom" src="../../public/images/Background-home-b.svg" alt="Poster" /> -->
  </div>
</template>

<script>
import IntroComponent from '@/components/IntroComponent.vue'; // @ is an alias to /src
import CardGallery from '@/components/CardGallery.vue';
import RequestToMint from '@/components/RequestToMint.vue';
import SnowComponent from '@/components/common/SnowComponent.vue';
import { navigateTo } from '@/helpers/navigation';
import { mapState } from 'vuex';
import api from '@/helpers/api'

export default {
	name: 'WelcomeView',

  components: {
    IntroComponent,
    CardGallery,
    RequestToMint,
    SnowComponent,
  },
  computed: {
    ...mapState({
      xRedirectionUrl: (state) => state.xRedirectionUrl,
    }),
  },
  data() {
    return {
      skip: 0,
      limit: 9,
      hide: true,
      files: [],
    };
  },
  async created() {
    this.$store.dispatch('resetUsersProgress');

    const { skip, limit, hide } = this;
    api.getFiles({ skip, limit, hide }).then((res) => {
      this.files = [...res.data.result];
    });
  },
  methods: {
    // Handle click event to navigate to "connect-account" page.
    handleClick(route) {
      navigateTo(route);
    },
  },
};
</script>
<style lang="css">
  img.bg_top {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  img.bg_bottom {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .intro {
      top: -270px;
    }
  .gallery-section-wrapper {
    min-height: 570px;
  }
  .intro .heading2,
  .request_to_mint_cont .heading2 {
      line-height: 20px;
      margin-top: 57px;
    }
  @media only screen and (max-width: 1650px) {
    .gallery-section-wrapper {
    min-height: 650px;
    }
    .intro {
      top: -210px;
    }
  }
  @media only screen and (max-width: 1500px) {
    .intro {
      top: -200px;
    }
    .gallery-section-wrapper {
     min-height: 700px;
    }
  }
</style>
