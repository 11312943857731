<template>
  <section class="main_section">
    <StepsComponentMobile v-if="currentStep < 4" />
    <ChooseCardFileMobile v-if="currentStep === 1" />
    <AddCardMessageMobile v-if="currentStep === 2" />
    <SignAndSendMobile v-if="currentStep === 3" />
    <CongratulationsComponentMobile v-if="currentStep === 4" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import StepsComponentMobile from '@/components/common/StepsComponentMobile.vue';
import ChooseCardFileMobile from '@/components/steps/ChooseCardFileMobile.vue';
import AddCardMessageMobile from '@/components/steps/AddCardMessageMobile.vue';
import SignAndSendMobile from '@/components/steps/SignAndSendCardMobile.vue';
import CongratulationsComponentMobile from '@/components/steps/CongratulationsComponentMobile.vue';

export default {
  name: 'AuthWrapper',
  components: {
    StepsComponentMobile,
    ChooseCardFileMobile,
    AddCardMessageMobile,
    SignAndSendMobile,
    CongratulationsComponentMobile,
  },
  computed: {
    ...mapState(['currentStep', 'passedSteps']),
  },
}
</script>

<style scoped>

</style>
