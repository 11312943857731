<template>
  <section class="connect-acc-section px-[2.5%] min-h-[calc(100vh-174px)] w-full pb-[40px]">
		<!-- Form to request minting of NFT -->
		<Heading2Component
    class="leading-[40px] !text-[32px] !font-black font-jost-black mt-[20px] mb-[38px] !text-blue-dark"
    text="Let’s get started!" />
		<div class="connect_acc_container px-[20px] pt-[30px] pb-[33px] m-auto text-center">
      <div class="flex flex-col items-center justify-center">
        <img class="w-[48px] h-[52px]" src="../../../public/images/user-icon.svg" />
        <p class="text-blue-dark mt-[19px] text-[18px] leading-[26px]">
          First, you need to connect your X account. Your X handle will be <br />
          important to find the card later.
          <br /><br />
          Click below to connect your X account
        </p>
      </div>
			<GeeqButtonComponent
        @onClick="connectAccount"
        classes="min-w-[90%] mt-[15px]"
        label="CONNECT!"
        type="button"
      />
			<h4 class="text-[18px] leading-[26px] font-jost-regular text-blue-dark mt-[15px]">
				Don’t worry! We’re only asking for your handle, nothing else!
			</h4>
		</div>
		<!-- /Form to request minting of NFT -->
	</section>
</template>

<script>
import Heading2Component from '@/components/common/Heading2Component.vue';
import GeeqButtonComponent from '@/components/common/GeeqButtonComponent.vue';
import { setToStorage } from '@/helpers/sessionStorage';
import { mapState } from 'vuex';

export default {
  name: 'ConnectAccount',
  components: {
    Heading2Component,
		GeeqButtonComponent,
  },
  computed: {
    ...mapState({
      xRedirectionUrl: (state) => state.xRedirectionUrl,
    }),
  },
  data() {
    return {
      // Your data properties here
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
		connectAccount() {
      // redirect to the url
      if (this.xRedirectionUrl) {
        window.location.replace(this.xRedirectionUrl);
      }
		},
	},
};
</script>

<style scoped>
.connect-acc-section {
  background-image: url('../../../public/images/bg-blue-light.png');
  background-position: center top;
  background-size: auto;
  background-repeat: no-repeat;
}
/* Your CSS styles here */
.connect_acc_container {
  box-shadow: 0px 17px 34px 0px #0000001A;
  background: #FFFFFF;
  width: 95%;
  border-radius: 10px;
}
@media only screen and (max-width: 940px) {
  .connect_acc_container {
    max-width: unset;
  }
  }
</style>
