<template>
  <router-view/>
  <FooterComponent />
</template>
<script>
import FooterComponent from '@/components/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    FooterComponent,
  },
  async created() {
    // await this.$store.dispatch('askToRebuild');
    await this.$store.dispatch('getXRedirectionUrl');
    await this.$store.dispatch('getBlockExplorerHost');
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #3D94C3;
}
</style>
