<template>
  <svg width="26" height="33" viewBox="0 0 26 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_970_8759)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.10081 19.281C9.85481 22.9876 13.1349 25.781 17.0625 25.781H24.375C24.8235 25.781 25.1875 25.417 25.1875 24.9685V17.656C25.1875 13.7194 22.3819 10.4337 18.6639 9.68944C18.3284 5.06144 14.4625 1.40601 9.75 1.40601C4.81731 1.40601 0.8125 5.41082 0.8125 10.3435V18.4685C0.8125 18.917 1.1765 19.281 1.625 19.281H9.10081ZM18.6314 11.3469C18.1691 15.4793 14.885 18.7634 10.7534 19.2249C11.4554 22.0557 14.0156 24.156 17.0625 24.156H23.5625V17.656C23.5625 14.6091 21.4622 12.0489 18.6314 11.3469ZM21.125 19.281H16.25C15.8015 19.281 15.4375 19.645 15.4375 20.0935C15.4375 20.542 15.8015 20.906 16.25 20.906H21.125C21.5735 20.906 21.9375 20.542 21.9375 20.0935C21.9375 19.645 21.5735 19.281 21.125 19.281ZM9.75 3.03101C13.7857 3.03101 17.0625 6.30782 17.0625 10.3435C17.0625 14.3792 13.7857 17.656 9.75 17.656H2.4375V10.3435C2.4375 6.30782 5.71431 3.03101 9.75 3.03101ZM19.5 17.656H21.125H21.1299C21.5759 17.6536 21.9375 17.2904 21.9375 16.8435C21.9375 16.395 21.5735 16.031 21.125 16.031H19.5C19.0515 16.031 18.6875 16.395 18.6875 16.8435C18.6875 17.292 19.0515 17.656 19.5 17.656ZM11.8763 13.6187L12.4256 14.1679C12.7424 14.4848 13.2576 14.4848 13.5744 14.1679C13.8913 13.8511 13.8913 13.3359 13.5744 13.0191L13.0252 12.4698C13.5216 11.7499 13.8125 10.8773 13.8125 9.93726C13.8125 7.47051 11.8105 5.46851 9.34375 5.46851C6.877 5.46851 4.875 7.47051 4.875 9.93726C4.875 12.404 6.877 14.406 9.34375 14.406C10.2838 14.406 11.1564 14.1151 11.8763 13.6187ZM11.8454 11.2901C12.0632 10.8879 12.1875 10.4264 12.1875 9.93726C12.1875 8.36751 10.9135 7.09351 9.34375 7.09351C7.774 7.09351 6.5 8.36751 6.5 9.93726C6.5 11.507 7.774 12.781 9.34375 12.781C9.83288 12.781 10.2944 12.6567 10.6966 12.4389L9.98806 11.7304C9.67119 11.4136 9.67119 10.8984 9.98806 10.5816C10.3049 10.2647 10.8201 10.2647 11.1369 10.5816L11.8454 11.2901Z" :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_970_8759">
        <rect width="26" height="32.5" fill="white" transform="translate(0 0.0467529)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CommentIcon',
  props: {
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      color: this.isActive ? '#0095C5' : '#7f7f7f',
    };
  },
};
</script>
