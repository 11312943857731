import { createStore } from 'vuex';
import api from '@/helpers/api';
import { getFromStorage, setToStorage, deleteFromStorage } from '@/helpers/sessionStorage';

export default createStore({
  state: {
    currentUser: null,
    currentRoute: 'home',
    currentStep: 1,
    passedSteps: [],
    xRedirectionUrl: '',
    blockExplorerHost: '',
    isLoggedIn: !!getFromStorage('token'),
    selectedFile: null,
    metaData: {name: '' , from: '', to: '', message: ''},
    nft: null,
  },
  getters: {
  },
  mutations: {
    SET_CURRENT_ROUTE(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.currentRoute = payload;
    },
    SET_CURRENT_STEP(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.currentStep = payload;
    },
    SET_PASSED_STEPS(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.passedSteps = payload;
    },
    SET_X_REDIRECTION_URL(state, url) {
      // eslint-disable-next-line no-param-reassign
      state.xRedirectionUrl = url;
    },
    SET_BLOCK_EXPLORER_HOST(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.blockExplorerHost = data.url;
    },
    SET_SELECTED_FILE(state, img) {
      // eslint-disable-next-line no-param-reassign
      state.selectedFile = img;
    },
    SET_METADATA(state, data: { name: string, value: string }) {
      const { name, value } = data;
      // eslint-disable-next-line no-param-reassign
      state.metaData[name as keyof typeof state.metaData] = value;
    },
    LOGIN(state) {
      // eslint-disable-next-line no-param-reassign
      state.isLoggedIn = true;
    },
    LOGOUT(state) {
      // eslint-disable-next-line no-param-reassign
      state.isLoggedIn = false;
      state.currentUser = null;
      deleteFromStorage('token');
    },
    SET_CURRENT_USER(state, user) {
      // eslint-disable-next-line no-param-reassign
      state.currentUser = user;
    },
    SET_NFT(state, nft) {
      // eslint-disable-next-line no-param-reassign
      state.nft = nft;
    }
  },
  actions: {
    async SET_TIME_OUT({ commit, state, dispatch }) {
      if (state.selectedFile && (state.selectedFile as { id?: number }).id) {
        const imgId = (state.selectedFile as { id: number }).id;

        if (state.selectedFile && imgId) {
          dispatch('freeImage', imgId);
        }
      }
      commit('SET_CURRENT_STEP', 1);
      commit('SET_PASSED_STEPS', []);
      commit('SET_SELECTED_FILE', null);
      state.metaData = {name: '' , from: '', to: '', message: ''};
    },
    async LOGIN({ commit, state }) {
      commit('LOGIN');
      if (state.isLoggedIn && !state.currentUser) {
        api.getAuthUser()
        .then((res: any) => { commit('SET_CURRENT_USER', res.data); })
        .catch(() => { commit('LOGOUT'); });
      }
    },
    async getXRedirectionUrl({ commit }) {
      api.getRedirectionUrl().then((res: any) => {
        const url = res.data;
        if (!url) {
          throw new Error('Redirection URL is empty');
        }

        commit('SET_X_REDIRECTION_URL', url);
      });
    },
    async getBlockExplorerHost({ commit }) {
      api.getBlockExplorerHost().then((res: any) => {
        const url = res.data;
        if (!url) {
          throw new Error('Block Explorer URL is empty');
        }

        commit('SET_BLOCK_EXPLORER_HOST', url);
      });
    },
    async askToRebuild() {
      api.askToRebuild().then((res: any) => {
        console.log(res);
      });
    },
    async getNft({ commit, state }, id: number) {
      api.getNftById(id).then((res: any) => {
        commit('SET_NFT', { ...res.data, id });
        commit('SET_PASSED_STEPS', [...state.passedSteps, state.currentStep]);
        commit('SET_CURRENT_STEP', state.currentStep + 1);
      });
    },
    freeImage({ commit }, id: number) {
      api.freeImage(id);
    },
    resetUsersProgress({ commit, dispatch, state }) {
      if (state.selectedFile && (state.selectedFile as { id?: number }).id) {
        const imgId = (state.selectedFile as { id: number }).id;

        if (state.selectedFile && imgId) {
          dispatch('freeImage', imgId);
        }
      }
      commit('SET_CURRENT_STEP', 1);
      commit('SET_PASSED_STEPS', []);
      commit('SET_NFT', null);
      commit('SET_SELECTED_FILE', null);
      state.metaData = { name: '', from: '', to: '', message: '' };
    },
  },
  modules: {
  },
});
