<template>
  <ConnectAccountMobile />
</template>

<script>
import ConnectAccountMobile from '@/components/steps/ConnectAccountMobile.vue';

export default {
  name: 'GuestWrapper',
  components: {
    ConnectAccountMobile,
	},
}
</script>
