<template>
  <div class="min-h-[calc(100vh-70px)] px-[2.5%] pb-[38px]">
		<!-- Page Heading -->
		<Heading2Component
			text="1. Choose image"
			class="mt-[40px] !text-[32px] !leading-[40px] font-jost-black !font-black !text-blue-dark"
		/>
		<!-- /Page Heading -->

		<div class="surprise-section m-auto mt-[42px] mb-[30px] pb-[20px] flex justify-between border-b border-[#CDEDFF]">

		<!-- Switch to show / hide unavailable items in the list -->
			<RoundedSwitchComponent
				:value="hideUnavailable"
				@onInput="changeHideUnavailableOption"
				label="Hide unavailable"
				class="mt-[20px]"
			/>
		<!-- /Switch to show / hide unavailable items in the list -->

		<!-- Section to choose "Mystery box" option -->
			<div>
				<span class="btn-label mr-[30px] text-[18px] font-jost-regular leading-[26px] text-blue-dark inline-block">
					Can't decide?
				</span>
				<ButtonComponent
					@onClick="surprise"
					label="SURPRISE ME!"
          type="button"
					classes="surprise_btn font-jost-bold transition-all rounded-[10px] text-blue-light !font-bold
          hover:border-[#00adff] !py-[5px] px-[36px] !text-[14px] h-[46px] w-[222px] !leading-[32px]"
				/>
			</div>
		<!-- /Section to choose "Mystery box" option -->
		</div>

		<!-- NFT file gallery section -->
    <FileGallery
			classes="image_gallery_wrapper m-auto !px-0"
      :isHidden="hideUnavailable"
      @selectFile="handleSelectFile"
		/>
		<!-- /NFT file gallery section -->
	</div>
</template>

<script>
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Heading2Component from '@/components/common/Heading2Component.vue';
import FileGallery from '@/components/FileGallery.vue';
import RoundedSwitchComponent from '@/components/common/RoundedSwitchComponent.vue';
import ButtonComponent from '@/components/common/ButtonComponent.vue';
import { mapState } from 'vuex';
import api from '@/helpers/api';

export default {
	name: 'ChooseCardFile',
	components: {
		Heading2Component,
		FileGallery,
		RoundedSwitchComponent,
		ButtonComponent,
	},
  computed: {
    ...mapState(['currentStep', 'passedSteps', 'selectedFile']),
  },
	data() {
    return {
			hideUnavailable: false,
    };
  },
  created() {
    this.$store.dispatch('resetUsersProgress');
  },
  mounted() {
    window.scrollTo(0, 0);
  },
	methods: {
		// Change the value of the hideUnavailable option
		changeHideUnavailableOption(val) {
			this.hideUnavailable = val;
		},
		// Handle click on the "Surprise me!" button
		surprise() {
      if (this.selectedFile) {
        this.$store.dispatch('freeImage', this.selectedFile.id);
        this.selectedFile = null;
      }

      api.getSurprise()
      .then((res) => {
        this.$store.commit('SET_SELECTED_FILE', res.data);
        this.$store.commit('SET_PASSED_STEPS', [...this.passedSteps, this.currentStep]);
        this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
      });
		},
    handleSelectFile(file) {
      if (this.selectedFile) {
        const isSameFile = this.selectedFile.id === file.id;
        this.$store.dispatch('freeImage', this.selectedFile.id);
        this.selectedFile = null;
        if (isSameFile) return;
      }

      api.selectFile(file.id)
      .then(() => {
        this.$store.commit('SET_SELECTED_FILE', file);
        this.$store.commit('SET_PASSED_STEPS', [...this.passedSteps, this.currentStep]);
        this.$store.commit('SET_CURRENT_STEP', this.currentStep + 1);
      })
      .catch((err) => {
        const msg = err.response.data.details;
        toast.error(msg, {
          position: "bottom-right",
          duration: 2000,
          timeout: 2000,
        });
      });
    },
	},
};
</script>
<style>
  .surprise_btn {
    box-shadow: 0px 0px 50px 0px #A6DFFF;
  }
  .surprise-section {
    max-width: 960px;
    align-items: baseline;
  }
  .image_gallery_wrapper {
    justify-items: center;
    max-width: 950px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .image_gallery_wrapper .card_item {
    width: 225px !important;
    height: 270px !important;
  }
  @media only screen and (max-width: 1399px) {
  .intro {
    margin-top: -90px;
  }
  .image_gallery_wrapper {
    max-width: 850px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .surprise-section {
    max-width: 850px;
  }
  }
  @media only screen and (max-width: 1200px) {
    .surprise-section {
      max-width: 750px;
    }
    .image_gallery_wrapper {
      max-width: 750px;
    }
  }
  @media only screen and (max-width: 1040px) {
    .surprise-section {
      max-width: 650px;
    }
    .image_gallery_wrapper {
      max-width: 650px;
    }
  }
  @media only screen and (max-width: 991px) {
    .intro {
      margin-top: -50px;
    }
  }
  @media only screen and (max-width: 940px) {
    .surprise-section {
      max-width: 490px;
    }
    .image_gallery_wrapper {
      max-width: 490px;
    }
    .surprise-section {
      align-items: center;
    }
    .surprise-section .btn-label {
      margin-bottom: 10px;
      align-items: center;
    }
  }



</style>
