<template>
  <svg width="26" height="34" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M16.8764 18.5371L18.5908 20.2808C18.4348 20.6066 18.3511 20.9665 18.3511 21.3354C18.3511 21.9821
    18.6079 22.6021 19.0645 23.0587C20.0663 24.0605 20.0249 24.0191 21.0267 25.0209C21.4833 25.4775 22.1033
    25.7343 22.75 25.7343C23.3967 25.7343 24.0167 25.4775 24.4733 25.0209C24.9307 24.5634 25.1875 23.9435
    25.1875 23.2968C25.1875 22.6508 24.9307 22.0301 24.4733 21.5734C23.4723 20.5724 23.5129 20.6131 22.5119
    19.6121C22.0553 19.1546 21.4346 18.8979 20.7886 18.8979C20.423 18.8979 20.0663 18.9799 19.7421
    19.1335L18.0221 17.385C19.4513 15.6885 20.3125 13.4988 20.3125 11.1093C20.3125 5.72807 15.9437 1.35925
    10.5625 1.35925C5.18131 1.35925 0.8125 5.72807 0.8125 11.1093C0.8125 16.4904 5.18131 20.8593 10.5625
    20.8593C12.9699 20.8593 15.1751 19.985 16.8764 18.5371ZM20.2142 20.7609C20.3661 20.6082 20.5733 20.5229
    20.7886 20.5229C21.0039 20.5229 21.2103 20.6082 21.3631 20.7609C22.3641 21.7619 22.3234 21.7213 23.3244
    22.7223C23.4772 22.8751 23.5625 23.0814 23.5625 23.2968C23.5625 23.5121 23.4772 23.7193 23.3244
    23.8712C23.1725 24.0239 22.9653 24.1093 22.75 24.1093C22.5347 24.1093 22.3275 24.0239 22.1756
    23.8712C21.1746 22.8702 21.2152 22.9108 20.2142 21.9098C20.0614 21.7579 19.9761 21.5507 19.9761
    21.3354C19.9761 21.1201 20.0614 20.9129 20.2142 20.7609ZM10.5625 2.98425C15.0467 2.98425 18.6875
    6.62507 18.6875 11.1093C18.6875 15.5934 15.0467 19.2343 10.5625 19.2343C6.07831 19.2343 2.4375 15.5934
    2.4375 11.1093C2.4375 6.62507 6.07831 2.98425 10.5625 2.98425Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    isActive: {
      type: Boolean,
    },
  },
  data() {
    return {
      color: this.isActive ? '#0095C5' : '#7f7f7f',
    };
  },
};
</script>
